import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TableModule } from 'primeng/table'
import { SharedModule } from 'src/app/shared/shared.module'
import { NgSelectModule } from '@ng-select/ng-select'
import { LoginsRoutingModule } from './logins-routing.module'
import { LoginComponent } from './components/login/login.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component'
import { AgmCoreModule } from '@agm/core'
import { AppSettings } from 'src/app/constants/app-setting'

@NgModule({
	imports: [
		CommonModule,
		LoginsRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		TableModule,
		SharedModule,
		NgSelectModule,
		AgmCoreModule.forRoot({
			//apiKey: 'AIzaSyCb9_n5gaBwI7qu_jO-Rr2zUzft3YNs3Kc'
			apiKey: AppSettings.FireBaseKey,
			//language: (localStorage && localStorage.gml) || 'vi',
		}),
	],
	declarations: [LoginComponent, ForgetPasswordComponent],
})
export class LoginsModule {}
