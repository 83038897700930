export class AppSettings {
	public static HostingAddress = 'http://localhost'

	public static API_ADDRESS = 'https://apisso.lhhtxocop.vn/api/'
	//public static API_ADDRESS = 'http://localhost/Api_Author/api/'
	//public static API_DOWNLOADFILES = 'http://localhost:51132'
	//public static VIEW_FILE = 'http://14.177.236.88:6100/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	//public static DataFireStore = 'car'
	//public static NotificationFireStore = 'Notifications'
	public static SIGNALR_ADDRESS = 'https://apisso.lhhtxocop.vn/signalr'

	//public static API_ADDRESS = 'http://14.177.236.88:1221/api/'
	//public static API_DOWNLOADFILES = 'http://14.177.236.88:1221'
	//public static VIEW_FILE = 'http://14.177.236.88:6100/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	//public static DataFireStore = 'car'
	//public static NotificationFireStore = 'Notifications'
	//public static SIGNALR_ADDRESS = 'http://14.177.236.88:1221/signalr'

	//public static API_ADDRESS = 'https://pcccapp.ziz.vn:8581/api/'
	//public static API_DOWNLOADFILES = 'https://pcccapp.ziz.vn:8581'
	//public static VIEW_FILE = 'https://pcccapp.ziz.vn:8582/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	//public static DataFireStore = 'car_demo'
	//public static NotificationFireStore = 'Notifications'
	//public static SIGNALR_ADDRESS = 'https://pcccapp.ziz.vn:8581/signalr'

	//public static API_ADDRESS = 'https://pcccapp.bacninh.gov.vn:8581/api/'
	public static API_DOWNLOADFILES = 'https://apisso.lhhtxocop.vn/'
	
	public static VIEW_FILE = 'https://apisso.lhhtxocop.vn/DocViewer?fileurl='
	public static FireBaseKey = 'AIzaSyAnVVNhsSkPWcIXNmGmWd-rSNp7JAxl'
	public static NotificationFireStore = 'Notifications'
	public static DataFireStore = 'car_demo'
	//public static SIGNALR_ADDRESS = 'https://pcccapp.bacninh.gov.vn:8581/signalr'
}
