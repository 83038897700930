import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Api } from '../constants/api'
import { AppSettings } from '../constants/app-setting'
import { RESPONSE_STATUS } from '../constants/CONSTANTS'

@Injectable({
	providedIn: 'root',
})
export class UserInfoStorageService {
	userName: string
	accessToken: string
	permissions: string
	isSaveLogin: boolean
	unitName: string
	role: number

	constructor(private http: HttpClient, private _router: Router) {}
	setKeyRemember(key: string): void {
		localStorage.setItem('KeyRework', key)
	}
	setReturnUrl(url: string): void {
		localStorage.setItem('ReturnUrl', url)
	}
	setUserId(id: string): void {
		localStorage.setItem('userId', id)
	}

	setAccessToken(token): void {
		localStorage.setItem('accessToken', token)
	}

	setSaveLogin(isSavelogin: boolean): void {
		localStorage.setItem('isSaveLogin', String(isSavelogin))
	}

	setPermissions(permissions: string): void {
		localStorage.setItem('permissions', permissions)
	}

	setFunctions(functions: string): void {
		localStorage.setItem('functions', functions)
	}

	setPermissionCategories(cate: string): void {
		localStorage.setItem('permissionCategories', cate)
	}

	setIsHaveToken(value: boolean): void {
		localStorage.setItem('IsHaveToken', String(value))
	}

	setRole(value): void {
		localStorage.setItem('Role', value)
	}

	setFullName(value): void {
		localStorage.setItem('FullName', value)
	}

	setUnitName(value): void {
		localStorage.setItem('unitName', value)
	}

	setUnitId(value): void {
		localStorage.setItem('unitId', value)
	}

	setTypeId(value): void {
		localStorage.setItem('typeId', value)
	}

	setIsSuperAdmin(value: boolean): void {
		localStorage.setItem('isSuperAdmin', String(value))
	}

	setIsMain(value): void {
		localStorage.setItem('isMain', value)
	}

	setIsBusinessCustomer(value): void {
		localStorage.setItem('isBusinessCustomer', value)
	}
	setIsWaterSource(value): void {
		localStorage.setItem('isWaterSource', value)
	}
	setIsVehicle(value): void {
		localStorage.setItem('isVehicle', value)
	}
	setIsFireHydrant(value): void {
		localStorage.setItem('isFireHydrant', value)
	}

	setTypeObject(value): void {
		localStorage.setItem('typeObject', value)
	}

	setUserName(name: string): void {
		localStorage.setItem('userName', name)
	}

	setAvatar(name: string): void {
		localStorage.setItem('avatar', name)
	}

	getKeyRemember(): string {
		return localStorage.getItem('KeyRework')
	}

	getUnitName(): string {
		return localStorage.getItem('unitName')
	}

	getUnitId(): number {
		return localStorage.getItem('unitId') as any
	}

	getTypeId(): number {
		return localStorage.getItem('typeId') as any
	}

	getUserId(): number {
		return localStorage.getItem('userId') as any
	}

	getIsSuperAdmin(): boolean {
		return localStorage.getItem('isSuperAdmin') === 'true'
	}

	getIsMain(): boolean {
		return localStorage.getItem('isMain') === 'true'
	}

	getIsBusinessCustomer(): boolean {
		return localStorage.getItem('isBusinessCustomer') === 'true'
	}
	getIsWaterSource(): boolean {
		return localStorage.getItem('isWaterSource') === 'true'
	}
	getIsVehicle(): boolean {
		return localStorage.getItem('isVehicle') === 'true'
	}
	getIsFireHydrant(): boolean {
		return localStorage.getItem('isFireHydrant') === 'true'
	}

	getReturnUrl(): string {
		return localStorage.getItem('ReturnUrl')
	}

	getAccessToken(): string {
		return localStorage.getItem('accessToken')
	}

	getSaveLogin(): boolean {
		return localStorage.getItem('isSaveLogin') === 'true'
	}

	getPermissions(): string {
		return localStorage.getItem('permissions')
	}

	getFunctions(): string {
		return localStorage.getItem('functions')
	}

	getPermissionCategories(): string {
		return localStorage.getItem('permissionCategories')
	}

	getIsHaveToken(): boolean {
		return localStorage.getItem('IsHaveToken') === 'true'
	}

	getUserName(): string {
		return localStorage.getItem('userName')
	}

	getAvatar(): string {
		return localStorage.getItem('avatar')
	}

	getFullName(): string {
		return localStorage.getItem('FullName')
	}

	getRole() {
		var role = localStorage.getItem('Role')
		if (role == 'null') return null
		else return role
	}

	getTypeObject(): number {
		return localStorage.getItem('typeObject') as any
	}

	clearStoreageAll(): void {
		this.setAccessToken('')
		this.setFunctions('')
		this.setIsHaveToken(false)
		this.setPermissionCategories('')
		this.setPermissions('')
		this.setSaveLogin(false)
		this.setUserId('')
		this.setRole('')
		this.setFullName('')
	}
	clearStoreage(): void {
		if (this.getSaveLogin()) {
			var key = atob(this.getKeyRemember())
			let reqtoken = {
				UserName: this.getUserName(),
				Password: key,
			}
			this.http.post<any>(AppSettings.API_ADDRESS + Api.RESTORETOKEN, reqtoken).subscribe((response) => {
				if (response.success != RESPONSE_STATUS.success) {
					this.clearStoreageAll()
					this._router.navigate(['/dang-nhap'])
				} else {
					this.setAccessToken(response.accessToken)
					this.setPermissionCategories(response.permissionCategories)
					this.setFunctions(response.functions)
					this.setPermissions(response.permissions)
					var ReturnlUrl = this.getReturnUrl()
					if (ReturnlUrl != undefined && ReturnlUrl != '' && ReturnlUrl != null && ReturnlUrl.includes('quan-tri')) {
						this._router.navigateByUrl(ReturnlUrl)
					} else {
						this._router.navigate(['/quan-tri'])
					}
				}
			})
		} else {
			this.clearStoreageAll()
			this._router.navigate(['/dang-nhap'])
		}
	}
}
