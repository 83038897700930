export class CONSTANTS {
	public static FILEACCEPT = '.pdf, .png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx'
	public static FILEACCEPT_FORM_ADMINISTRATION = '.pdf, .doc, .docx, .xls, .xlsx'
	public static FILEACCEPTAVATAR = '.png, .jpg, .jpeg'
	public static PAGE_SIZE = 20
	public static PAGE_INDEX = 1

	public static SP_DIEN_LUC = 1
	public static SP_Y_TE = 2
	public static SP_GIAO_THONG = 3
	public static SP_CAP_NUOC = 4
}

export class RESPONSE_STATUS {
	public static success = 'OK'
	public static orror = 'ORROR'
	public static incorrect = 'INCORRECT'
}

export class LOG_ACTION {
	public static login = 'Đăng nhập'
	public static logout = 'Đăng xuất'
	public static GETLIST = 'Trả về danh sách'
	public static GETINFO = 'Trả về thông tin'
	public static GETDATACREATE = 'Trả về dữ liệu khởi tạo'
	public static INSERT = 'Thêm mới'
	public static UPDATE = 'Cập nhật'
	public static UPDATESTATUS = 'Cập nhật trạng thái'
	public static UPDATEISSHOW = 'Cập nhật ẩn hiện'
	public static DELETE = 'Xóa'
	public static CHANGEPASSWORD = 'Đổi mật khẩu'
	public static FORGETPASSWORD = 'Lấy lại mật khẩu'
	public static EXPORT = 'Xuất danh sách'
	public static APPROVED = 'Phê duyệt'
	public static DENIED = 'Từ chối'
	public static UNLOCK = 'Mở khóa'
}

export class LOG_OBJECT {
	public static login = 'Đăng nhập'
	public static CA_FIELD = 'Danh mục lĩnh vực'
	public static CA_WORD = 'Danh mục thư viện từ ngữ'
	public static CA_FIREHYDRANT = 'Danh mục cột nước chữa cháy'
	public static CA_WATER_SOURCE = 'Danh mục nguồn nước'
	public static CA_WARNING_TYPE = 'Danh mục loại cảnh báo'
	public static CA_VEHICLE_TYPE = 'Danh mục loại phương tiện'
	public static CA_SUPPORT_TYPE = 'Danh mục loại lực lượng hỗ trợ'
	public static CA_SIGNAL_TYPE = 'Danh mục loại tín hiệu'
	public static CA_REASON = 'Danh mục nguyên nhân'
	public static CA_POSITION = 'Danh mục chức vụ'
	public static CA_OBJECT = 'Danh mục đối tượng'
	public static CA_MANAGEMENT_UNIT = 'Danh mục đơn vị quản lý'
	public static CA_DOCUMENT_TYPE = 'Danh mục loại tài liệu'
	public static CA_DamageLevel = 'Danh mục mức độ thiệt hại'
	public static CA_DEVICE_TYPE = 'Danh mục loại thiết bị'
	public static CA_DEVICE = 'Danh mục thiết bị'
	public static CA_AREA = 'Danh mục khu vực PCCC'
	public static SY_LOG = 'Lịch sử hệ thống'
	public static SY_USER = 'Người dùng'
	public static SY_SOLIDER = 'Chiến sĩ'
	public static SY_UNIT = 'Cơ cấu tổ chức'
	public static SY_USERUNIT = 'Người dùng thuộc Cơ cấu tổ chức'
	public static CA_REGION = 'Danh mục hành chính'
	public static SY = 'Hệ thống'	
	public static MONITOR = 'Màn hình giám sát'
	public static NOTIFICATION = 'Thông báo'
	public static SY_SMS = 'Cấu hình tin nhắn'
	public static SY_EMAIL = 'Cấu hình email'
	public static SY_ROLE = 'Nhóm quyền'
	public static SY_USER_GET_BY_GROUP = 'Người dùng theo nhóm người dùng'
	public static SY_USER_GET_BY_ROLE = 'Người dùng theo nhóm quyền'
	public static SY_GROUP_USER = 'Nhóm người dùng'
	public static SY_SYSTEMCONFIG = 'Cấu hình tham số hệ thống'
	public static SY_SYSTEMSETTING = 'Thiết lập hệ thống'
	public static SY_DATANETWORK = 'Thu thập dữ liệu qua mạng'
	public static SY_WEBSERVICES = 'Services tương tác với thiết bị báo cháy'
	public static SY_SCHEDULE = 'Lập lịch hệ thống'
	public static BC_BusinessCustomer = 'Cơ sở khách hàng'
	public static BC_ReportCustomer = 'Báo cáo cơ sở khách hàng'
	public static DO_DOCUMENT = 'Tài liệu pháp luật PCCC'
	public static CA_VEHICLE = 'Phương tiện chữa cháy'
	public static CA_TEAM = 'Đội phòng cháy chữa cháy'
	public static CA_MEMBERTEAM = 'Thành viên đội phòng cháy chữa cháy'
	public static TE_TestSchedule = 'Lịch kiểm tra'
	public static TE_TestSchedule_Customer = 'Lịch kiểm tra - cơ sở'
	public static TE_TestSchedule_Files = 'Lịch kiểm tra - file'
	public static TE_TestSchedule_Member = 'Lịch kiểm tra - thành viên'
	public static BC_BusinessCustomer_Approved = 'Cơ sở khách hàng - phê duyệt'
	public static BC_WaterSourceInside = 'Cơ sở khách hàng - nguồn nước bên trong'
	public static BC_WaterSourceOutside = 'Cơ sở khách hàng - nguồn nước bên ngoài'
	public static BC_Files = 'Cơ sở khách hàng - file'
	public static SY_FUNCTION = 'Module chức năng'
	public static FI_FireInfo = 'Thông tin đám cháy'
	public static FI_CASE = 'Vụ việc'
	public static CA_FORCE_SUPPORT = 'Lực lượng hỗ trợ'
	public static SP_Electricity = 'Lực lượng hỗ trợ - Y tế'

	public static RE_COMEINAND = 'Báo cáo lệnh điều động'
	public static RE_CASE_REASON = 'Báo cáo vụ việc PCCC theo nguyên nhân'
	public static RE_CASE_DAMAGE = 'Báo cáo vụ việc PCCC theo mức độ thiệt hại'
	public static RE_CASE_TIME = 'Báo cáo vụ việc PCCC theo thời gian'
	public static RE_CASE_WORK = 'Công tác PCCC theo thời gian'
	public static RE_FORCE = 'Thống kê lực lượng thường trực sẵn sàng chữa cháy và CNCH'

	public static ST_CASE_REASON = 'Thống kê vụ việc PCCC theo nguyên nhân'
	public static ST_CASE_DAMAGE = 'Thống kê vụ việc PCCC theo mức độ thiệt hại'
	public static ST_CASE_TIME = 'Thống kê vụ việc PCCC theo thời gian'
	public static ST_CASE_WORK = 'Thống kê công tác PCCC theo thời gian'
	
}

export class MESSAGE_COMMON {
	public static ADD_SUCCESS = 'Thêm mới thành công'
	public static DELETE_SUCCESS = 'Xóa thành công'
	public static UPDATE_SUCCESS = 'Cập nhật thành công'
	public static UNLOCK_SUCCESS = 'Mở khóa thành công'
	public static LOCK_SUCCESS = 'Khóa thành công'
	public static REVERT_SUCCESS = 'Khôi phục thành công'
	public static ADD_FAILED = 'Thêm mới thất bại'
	public static DELETE_FAILED = 'Xóa thất bại'
	public static UPDATE_FAILED = 'Cập nhật thất bại'
	public static PUBLISH_SUCCESS = 'Công bố thành công'
	public static EXISTED_CODE = 'Mã đã tồn tại'
	public static EXISTED_NAME = 'Tên đã tồn tại'
	public static WITHDRAW_SUCCESS = 'Thu hồi thành công'
	public static SEND_SUCCESS = 'Gửi thành công'
	public static UPDATE_STATUS_SUCCESS = 'Cập nhật trạng thái thành công'
	public static APPROVED_SUCCESS = 'Phê duyệt thành công'
	public static UNAPPROVED_SUCCESS = 'Từ chối phê duyệt thành công'
	public static ACCEPT_SUCCESS = 'Tiếp nhận thành công'
	public static UNACCEPT_SUCCESS = 'Từ chối tiếp nhận thành công'
	public static REGISTER_CUSTOMER_SUCCESS = 'Hệ thống đã ghi nhận thông tin đăng  ký của cơ sở'
}

export class RECOMMENDATION_STATUS {
	public static CREATED = 1
	public static RECEIVE_WAIT = 2 //2 Chờ xử lý
	public static RECEIVE_DENY = 3 //3 Từ chối xử lý
	public static RECEIVE_APPROVED = 4 //4 Đã tiếp nhận
	public static PROCESS_WAIT = 5 //5 Chờ giải quyết
	public static PROCESS_DENY = 6 //6 Từ chối giải quyết
	public static PROCESSING = 7 //7 Đang giải quyết
	public static APPROVE_WAIT = 8 //8 Chờ phê duyệt
	public static APPROVE_DENY = 9 //9 Từ chối phê duyệt
	public static FINISED = 10 //10 Đã giải quyết

	public static UPDATED = 11 //11 Chỉ để lưu log
}

export class STEP_RECOMMENDATION {
	public static RECEIVE = 1 //Tiếp nhận
	public static PROCESS = 2 //2 Giải quyết
	public static APPROVE = 3 //3 Phê duyệt
}

export class PROCESS_STATUS_RECOMMENDATION {
	public static WAIT = 1 //Chờ xử lý
	public static APPROVED = 2 //2 Đồng ý xử lý
	public static DENY = 3 //3 Từ chối xử lý
	public static FORWARD = 4 //3 Chuyển xử lý
}

export const FILETYPE = [
	{ text: 'application/msword', value: 1 },
	{ text: 'text/plain', value: 1 },
	{ text: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', value: 1 },
	{ text: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', value: 1 },
	{ text: 'application/vnd.ms-word.document.macroEnabled.12', value: 1 },
	{ text: 'application/vnd.ms-word.template.macroEnabled.12', value: 1 },
	{ text: 'application/vnd.ms-excel', value: 2 },
	{ text: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', value: 2 },
	{ text: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', value: 2 },
	{ text: 'application/vnd.ms-excel.sheet.macroEnabled.12', value: 2 },
	{ text: 'application/vnd.ms-excel.template.macroEnabled.12', value: 2 },
	{ text: 'application/vnd.ms-excel.addin.macroEnabled.12', value: 2 },
	{ text: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', value: 2 },
	{ text: 'application/octet-stream', value: 2 },
	{ text: 'application/pdf', value: 3 },
	{ text: 'image/png', value: 4 },
	{ text: 'image/jpeg', value: 4 },
	{ text: 'image/gif', value: 4 },
	{ text: 'application/x-rar-compressed', value: 5 },
	{ text: 'application/zip', value: 5 },
	{ text: 'application/x-7z-compressed', value: 5 },
	{ text: 'application/vnd.ms-powerpoint', value: 6 },
	{ text: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', value: 6 },
	{ text: 'application/vnd.openxmlformats-officedocument.presentationml.template', value: 6 },
	{ text: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', value: 6 },
	{ text: 'application/vnd.ms-powerpoint.addin.macroEnabled.12', value: 6 },
	{ text: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', value: 6 },
	{ text: 'application/vnd.ms-powerpoint.template.macroEnabled.12', value: 6 },
	{ text: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', value: 6 },
]

export class REPORT {
	public static WARNING_TYPE = 1
	public static VEHICLE_TYPE = 2
	public static SUPPORT_TYPE = 3
	public static SINGNAL_TYPE = 4
	public static DEVICE_TYPE = 5
	public static DOC_TYPE = 6
	public static FIELD = 7;
	public static POSITION = 8;
	public static REASON = 9;
	public static DAMAGE = 10;
	public static MANAGEMENT_UNIT = 11;
	public static TYPEWATER = 12;

	public static RE_CASE_REASON = 100;
	public static RE_CASE_DAMAGE = 101;
	public static RE_CASE_TIME = 102;
	public static RE_CASE_WORK = 103;
	public static RE_CASE_CAREER = 104;
	public static RE_CASE_SHIFT = 105;
	public static ST_CASE_WORK = 103;
}
