<div class="authincation h-100">
	<div class="container-fluid h-100" >
		<div class="row justify-content-centeralign-items-center h-100">
			<div class="col">
				<div class="authincation-content shadow-none">
					<div class="row h-100">
						<div class="col-md-8 border-right border-brown remove-boder-right bgPrivate">
							<!-- <div class="row bg-image">
							</div> -->
							<div class="auth-form text-center banner-login">
								
							</div>
						</div>
						<div class="col-md-4 d-flex justify-content-center" style="background-color: #FFFBF4;">
							<div class="auth-form p-0" style="max-width: 100%">
								<div class="custom-tab-1">
									<ul class="nav nav-tabs nav-tabs-register bg-transparent border-bottom-0 justify-content-between">
									</ul>
									<div class="tab-content pt-3 align-items-center">
										<div class="tab-pane fade show active align-items-center" id="login-cbql" >
											<div class="row h-100 align-items-center">
												<div class="col">
													<div class="auth-form p-1">
														<div class="text-center">
															<a href="#"><img class="img-fluid logo-image" src="assets/dist/images/logo_ndt.png" alt=""></a>
														</div>
														<p class="title-app text-center text-dark cl-brown">QUẢN LÝ XÁC THỰC TẬP TRUNG SSO</p>
														<form [formGroup]="loginForm" #formDir="ngForm" (ngSubmit)="login()">
															<div class="form-group mb-4">
																<input class="form-control px-4 border-brown" style="font-size: 15px" placeholder="Nhập tài khoản, email..."
																	type="text" id="name" formControlName="name" [(ngModel)]="user.UserName" required>
																<div *ngIf="name.invalid && (name.dirty || name.touched || submitted)" class="has-error m-b-12">
																	<div *ngIf="name.errors.required">
																		<span class="help-block">Trường này không được để trống</span>
																	</div>
																</div>
															</div>
															<div class="form-group mb-4">
																<input class="form-control px-4 border-brown" type="password" style="font-size: 15px" id="pass"
																	placeholder="Mật khẩu..." formControlName="pass" [(ngModel)]="user.Password" required>
																<div *ngIf="pass.invalid && (pass.dirty || pass.touched || submitted)" class="has-error m-b-12">
																	<div *ngIf="pass.errors.required">
																		<span class="help-block">Trường này không được để trống</span>
																	</div>
																</div>
															</div>
															<div class="form-group captcha-form bg-white rounded-xl border border-brown position-relative">
																<div class="bg-white captcha d-flex justify-content-between align-items-center rounded-xl shadow position-absolute">
																	<img class="img-fluid rounded-xl" style="max-height: 70px; max-width: 70%; padding-left: 50px;" id="img-captcha"
																		[src]="captchaImage" />
																	<button type="button" (click)="reloadImage()" class="btn p-1 rounded-xl"><i class="bi bi-arrow-repeat"
																			style="font-size: 24px"></i></button>
																</div>
																<input type="text" formControlName="captcha" [(ngModel)]="captchaCode" maxlength="5" style="font-size: 15px"
																	placeholder="Nhập kết quả..." class="form-control bg-transparent px-4 border-0" value="">
															</div>
															<div *ngIf="captcha.invalid && (captcha.dirty || captcha.touched || submitted)" class="has-error m-b-12">
																<div *ngIf="captcha.errors.required">
																	<span class="help-block">Vui lòng nhập kết quả</span>
																</div>
															</div>
															<div class="form-group d-flex justify-content-between align-items-center mt-4 mb-2">
																<div class="form-group">
																	<div class="custom-control custom-checkbox ml-1" style="cursor: pointer;">
																		<input type="checkbox" class="custom-control-input" id="basic_checkbox_1" 
																			formControlName="isRemember" [(ngModel)]="isSaveLogin">
																		<label class="custom-control-label" for="basic_checkbox_1">Nhớ tài khoản</label>
																	</div>
																</div>
																<div class="form-group">
																	<a (click)="forgetPassWord()" style="cursor: progress;">Quên mật khẩu?</a>
																</div>
															</div>
															<div class="text-center">
																<button type="submit" class="mt-1 btn btn-orange text-white text-uppercase font-weight-normal btn-block border-0 rounded-xl"
																	style="font-size: 15px">Đăng nhập</button>
															</div>
															<!-- <div class="text-center mt-4 mb-2">
																<div class="form-group">
																	<a href="javascript:void()" (click)="registerCustormer()" style="cursor: progress;">Đăng ký </a>tài khoản?
																</div>
															</div> -->
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!--**********************************
  Scripts
***********************************-->
<!-- Required vendors -->
<script src="assets/dist/vendor/global/global.min.js"></script>
<script src="assets/dist/vendor/bootstrap-select/dist/js/bootstrap-select.min.js"></script>
<script src="assets/dist/js/custom.min.js"></script>
<script src="assets/dist/js/deznav-init.js"></script>
