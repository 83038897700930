import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServiceInvokerService } from '../commons/service-invoker.service'
import { Observable } from 'rxjs'
// import { LoginUserObject } from '../models/loginUserObject';
import { ForgetPasswordUserObject } from '../models/forgetPasswordUserObject'
import { AppSettings } from '../constants/app-setting'
import { Api } from '../constants/api'
import { LOG_ACTION, LOG_OBJECT } from '../constants/CONSTANTS'
import { BrowserInforService } from '../commons/browser-infor.service'
// import { ChangePasswordUserObject } from '../models/changePasswordUserObject';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	constructor(private browser: BrowserInforService, private http: HttpClient, private serviceInvoker: ServiceInvokerService) {}

	login(user: any): Observable<any> {
		user.logAction = LOG_ACTION.login
		user.logObject = LOG_OBJECT.SY
		user.logBrowser = this.browser.getInfor()
		return this.serviceInvoker.postlogin(user, AppSettings.API_ADDRESS + Api.LOGIN)
	}

	logOut(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.logout),
			logObject: encodeURIComponent(LOG_OBJECT.SY),
			logBrowser: this.browser.getInfor()
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.logOut, headers)
	}

	register(user: any): Observable<any> {
		return this.serviceInvoker.post(user, AppSettings.API_ADDRESS + Api.REGISTER)
	}

	forgetpassword(user: ForgetPasswordUserObject): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.FORGETPASSWORD) + " " + user.Email,
			logObject: encodeURIComponent(LOG_OBJECT.SY),
			logBrowser: this.browser.getInfor()
		}
		return this.serviceInvoker.postwithHeaders(user, AppSettings.API_ADDRESS + Api.FORGETPASSWORD,headers)
	}

	chagepassword(user: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.CHANGEPASSWORD) + " " + user.Email,
			logObject: encodeURIComponent(LOG_OBJECT.SY),
			logBrowser: this.browser.getInfor()
		}
		return this.serviceInvoker.postwithHeaders(user, AppSettings.API_ADDRESS + Api.CHANGEPASSWORD,headers)
	}

	restoreAccount(user: any): Observable<any> {
		return this.serviceInvoker.post(user, AppSettings.API_ADDRESS + Api.RESTORE_ACCOUNT)
	}
}
